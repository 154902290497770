<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <l-map
        style="height: 600px; z-index: 1 !important;"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker
          v-for="(hote, indH) in loadHotels"
          :key="indH"
          :lat-lng="[hote.hotel.latitud, hote.hotel.longitud]"
          @click="mostrarCard(hote)"
        >
          <l-icon
            :icon-anchor="staticAnchor"
            class-name="markerIcon"
            :style="$vuetify.theme.themes.light.primary"
          >
            <div class="markerIcon__price">
              {{ `${hote.currencyCode}${hote.priceDayC}` }}
            </div>
          </l-icon>
          <!--<l-popup @click="innerClick">
            <div>
              {{ hote.name }}
              ${{ hote.price }}
            </div>
          </l-popup>-->
        </l-marker>

        <l-control
          v-if="showCard"
          :position="'bottomleft'"
          class="custom-control-watermark"
        >
          <CardMap
            :item="cardHotel"
            @cerrarCard="cerrarCard"
          />
        </l-control>
      </l-map>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
  mdiStarOutline,
  mdiStar,
  mdiStarHalfFull,
  mdiBedEmpty,
} from '@mdi/js'

import CardMap from './CardMap.vue'

export default {
  components: {
    CardMap,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 13,
      center: [],
      markerLatLng: [],
      staticAnchor: [16, 37],
      iconSize: 64,
      showCard: false,
      cardHotel: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiBedEmpty,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultSelect: state => state.app.cotizadorHotelsResultSelect,
      cotizadorHotelsResultPaginate: state => state.app.cotizadorHotelsResultPaginate,
    }),
    loadHotels() {
      // console.log(this.cotizadorHotelsResultPaginate)
      const arr = []
      this.cotizadorHotelsResultPaginate.forEach(element => {
        if (element.hotel.latitud && element.hotel.longitud) {
          arr.push({
            ...element,
            priceC: Math.ceil(element.minPriceMark),
            priceDay: parseFloat(element.minPriceMark) / parseInt(element.days_search, 10),
            priceDayC: Math.ceil(element.minPriceMark / parseInt(element.days_search, 10)),
          })
        }
      })

      return arr
    },
  },
  created() {
    console.log(this.cotizadorHotelsResultPaginate)
    this.getCenter()
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'setIndexTarifaEconCotizadorIdaFligth',
      'setCotizadorHotelsResultSelect',
      'updateOcupationHotel',
      'setCotizadorHotelsRomms',
      'setCotizadorHotelsSelectMarkups',
    ]),
    getCenter() {
      let pos = 0
      this.cotizadorHotelsResultPaginate.forEach(element => {
        if (element.hotel.latitud && element.hotel.longitud) {
          if (pos === 0) {
            this.center = [element.hotel.latitud, element.hotel.longitud]
            pos = 1
          }
        }
      })
    },
    mostrarCard(item) {
      this.showCard = false
      setTimeout(() => {
        this.showCard = true
      }, 100)
      this.cardHotel = item
    },
    cerrarCard() {
      this.showCard = false
    },
  },
}
</script>
