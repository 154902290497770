<template>
  <fragment>
    <Loading
      v-if="paginando"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
    <v-row
      no-gutters
      class="py-0"
    >
      <v-col
        cols="12"
        md="5"
        class="pr-0"
      >
        <div class="d-flex justify-start align-center">
          <v-autocomplete
            v-if="cotizadorHotels.destino === null || cotizadorHotels.destino === ''"
            v-model="cotizadorHotels.destino"
            :items="itemsDestinos"
            :search-input.sync="searchDestinos"
            :label="$t('lbl.hotelDestinos')"
            outlined
            dense
            :item-text="getItemText"
            item-value="id"
            single-line
            clearable
            return-object
            class="pr-2"
            @click:clear="clearDestinos"
            @change="changeDestinos"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultDestinos ? $t('booking.noResultSearchFlight') : $t('lbl.hotelDestinos') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
          <v-text-field
            v-else
            ref="autocomplete"
            v-model="cotizadorHotels.destino.name"
            :label="$t('lbl.hotelDestinos')"
            outlined
            dense
            single-line
            clearable
            @click:clear="clearDestinos"
            @input="inputDestinos"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="pl-5"
      >
        <v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFromDateFormatted"
              :label="$t('lbl.dateIn')"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              style="border-radius: 5px 0px 0px 5px"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="cotizadorHotels.dateIn"
            no-title
            :min="minDate.toISOString().substr(0, 10)"
            locale="es"
            :show-current="false"
            @input="
              menuFrom = false
              activeToDate()
            "
          ></v-date-picker>
        </v-menu>
        <!--<vc-date-picker
          v-model="cotizadorHotels.dateIn"
          outlined
          dense
          hide-details
          :min-date="minDate"
          mode="date"
          :model-config="modelConfig"
          class="d-flex justify-center;z-index: 1 !important"
          @input="activeToDate"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.dateIn')"
              outlined
              dense
              :value="inputValue"
              style="border-radius: 5px 0px 0px 5px"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>-->
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="pl-5"
      >
        <v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedToDateFormatted"
              :disabled="!cotizadorHotels.dateIn"
              :label="$t('lbl.dateOut')"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              style="border-radius: 0px 5px 5px 0px"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="cotizadorHotels.dateOut"
            no-title
            locale="es"
            :show-current="false"
            :min="
              cotizadorHotels.dateIn
                ? $moment(cotizadorHotels.dateIn)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : minDate
            "
            @input="menuTo = false"
          ></v-date-picker>
        </v-menu>
        <!--<vc-date-picker
          v-model="cotizadorHotels.dateOut"
          outlined
          dense
          hide-details
          :min-date="
            $moment(cotizadorHotels.dateIn)
              .add(1, 'days')
              .toDate()
          "
          mode="date"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              ref="dateTo"
              :disabled="!cotizadorHotels.dateIn"
              :label="$t('lbl.dateOut')"
              outlined
              dense
              :value="inputValue"
              style="border-radius: 0px 5px 5px 0px"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>-->
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pl-5"
      >
        <v-btn
          align="left"
          block
          class="font-weight-medium"
          outlined
          color="#8a8d93"
          style="text-transform: capitalize; justify-content: left;"
          @click="changeOcupation"
        >
          <v-icon left>
            mdi-human-male-girl
          </v-icon>
          {{
            `${
              cotizadorHotels.ocupation
                ? `${cantViajeros} ${$t('lbl.travels')}, ${cotizadorHotels.ocupation.length} ${$t('lbl.roomD')}`
                : $t('lbl.ocupation')
            }`
          }}
        </v-btn>
        <span v-if="showOcupationRoomHotel">
          <!--class="container-chat col-lg-3 col-md-3 col-sm-3 col-xs-3 pl-7"-->
          <v-container
            fluid
            pa-0
            class="container-chat pl-7"
            :class="cotizadorHotels.ocupation.length === 1 ?
              'col-lg-3 col-md-3 col-sm-3 col-xs-3' : (
                (cotizadorHotels.ocupation.length >= 2 && cotizadorHotels.ocupation.length <= 4) ?
                  'col-lg-6 col-md-6 col-sm-6 col-xs-6' : (
                    'col-lg-9 col-md-9 col-sm-9 col-xs-9'
                  )
              )"
          >
            <HotelOcupation />
          </v-container>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <!--<v-col
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-model="cotizadorHotels.proveedor_id"
          :items="itemsProveedor"
          :search-input.sync="searchProveedor"
          hide-details
          hide-selected
          :label="$t('lbl.proveedor')"
          outlined
          dense
          item-text="name_comercial"
          item-value="id"
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.proveedor') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name_comercial"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="cotizadorHotels.categoria_id"
          :items="categories"
          item-text="cant_estrellas"
          item-value="id"
          :label="$t('menu.category')"
          outlined
          dense
          hide-details
          clearable
          multiple
        >
          !--<template v-slot:selection="{ item }">
            <span v-if="item.name !== null">
              {{ item.name }}
              <span v-if="item.plus">PLUS</span>
            </span>
            <span v-if="item.name === null">
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-rating
                    v-model="item.cant_estrellas"
                    color="primary"
                    size="25"
                    readonly
                  ></v-rating>
                </v-col>
              </v-row>
            </span>
          </template>--
          !--<template v-slot:selection="{ item }">
            <span v-if="item.name !== null">
              {{ item.name }}
              <span v-if="item.plus">PLUS</span>
            </span>
            <span v-if="item.name === null">
              {{ item.cant_estrellas }}
            </span>
          </template>--

          !--<template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-if="item.name !== null">
                {{ item.name }}
                <span v-if="item.plus">PLUS</span>
              </v-list-item-title>
              <v-list-item-title v-if="item.name === null">
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-rating
                      v-model="item.cant_estrellas"
                      color="primary"
                      readonly
                      size="30"
                    ></v-rating>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <p
                      v-if="item.plus"
                      class="pt-3"
                    >
                      PLUS
                    </p>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </template>--
        </v-select>
      </v-col>-->
      <!--<v-col
        cols="12"
        md="2"
      >
        !--<v-autocomplete
          v-model="cotizadorHotels.tipo_destino_id"
          :items="itemsTipoDestino"
          :search-input.sync="searchTipoDestino"
          hide-details
          hide-selected
          :label="$t('menu.typeDestination')"
          outlined
          dense
          item-text="name"
          item-value="id"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('menu.typeDestination') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>--
        <v-select
          v-model="cotizadorHotels.tipo_destino_id"
          :items="allTipoDestino"
          item-text="name"
          item-value="id"
          :label="$t('menu.typeDestination')"
          outlined
          dense
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span v-if="item.name">
              {{ item.name }}
              <span v-if="item.plus">PLUS</span>
            </span>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>-->
      <v-spacer></v-spacer>
      <v-col
        v-if="!loadingSearch && cotizadorHotelsResult.length > 0"
        cols="12"
        md="2"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="10"
          >
            <v-btn
              color="primary"
              width="100%"
              outlined
              @click="
                updateShowMap(!showMapHotels)
                scrollInto('showMap')
              "
            >
              <v-icon>mdi-map-marker</v-icon>
              <span class="pl-2">{{ !showMapHotels ? $t('lbl.show') : $t('lbl.hidden') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col
            cols="12"
            :md="
              !cotizadorHotels.destino &&
                !cotizadorHotels.dateIn &&
                !cotizadorHotels.dateOut &&
                cotizadorHotels.ocupation.length === 1 &&
                !cotizadorHotels.proveedor_id &&
                !cotizadorHotels.categoria_id &&
                !cotizadorHotels.tipo_destino_id
                ? '12'
                : '6'
            "
          >
            <v-btn
              color="primary"
              width="100%"
              :loading="loadingSearch"
              @click="
                updateShowMap(false)
                resetPagination()
                search()
              "
            >
              {{ $t('btn.search') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="
              cotizadorHotels.destino ||
                cotizadorHotels.dateIn ||
                cotizadorHotels.dateOut ||
                cotizadorHotels.ocupation.length > 1 ||
                cotizadorHotels.proveedor_id ||
                cotizadorHotels.categoria_id.length > 0 ||
                cotizadorHotels.tipo_destino_id
            "
            cols="12"
            md="6"
          >
            <v-btn
              color="error"
              width="100%"
              :disabled="loadingSearch"
              @click="clearForm"
            >
              {{ $t('btn.clear') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="!loadingSearch && cotizadorHotelsResult.length > 0">
      <DisponibilidadHotels
        id="showMap"
        :user="user"
      />
    </template>
    <NoResultsHotels v-else-if="oneSearch && cotizadorHotelsResult.length === 0" />

    <!--<v-row v-else-if="searchingHotel">-->
    <v-row v-if="searchingHotel">
      <template v-if="!isLoadingSkeleton">
        <v-col
          v-for="(hotel, indH) in hotelsSkeleton"
          :key="indH"
          cols="12"
        >
          <SkeletonResultsHotels
            :hotel="hotel"
            :days-search="daysSearch"
          />
        </v-col>
      </template>
    </v-row>

    <!--<NoResultsHotels v-else-if="searchingHotel" />-->
    <!--<div
      v-else
      style="min-height: 250px;"
    ></div> -->
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import HotelOcupation from './HotelOcupation.vue'

import DisponibilidadHotels from '../disponibilidad/hotels/Index.vue'
import NoResultsHotels from './NoResultsHotels.vue'
import SkeletonResultsHotels from './SkeletonResultsHotels.vue'

export default {
  components: {
    Loading,
    HotelOcupation,
    // eslint-disable-next-line vue/no-unused-components
    DisponibilidadHotels,
    SkeletonResultsHotels,
    // eslint-disable-next-line vue/no-unused-components
    NoResultsHotels,
  },
  data() {
    return {
      oneSearch: false,
      paginando: false,
      menu: false,
      page: 1,
      per_page: 8,
      itemsDestinos: [],
      noResultDestinos: false,
      searchDestinos: null,
      loading: false,
      loadingSearch: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      model: {},
      transmisiones: [],
      no_include_hour_recogida: true,
      no_include_hour_entrega: true,
      hours_recogida: [],
      hours_entrega: [],
      semanas: [],
      minDate: new Date(),
      minDateOrigin: new Date(),
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      categories: [],
      itemsTipoDestino: [],
      allTipoDestino: [],
      searchTipoDestino: null,
      menuFrom: false,
      menuTo: false,
      isLoadingSkeleton: true,
      hotelsSkeleton: [],
      user: {},
    }
  },
  computed: {
    ...mapState({
      searchingHotel: state => state.app.searchingHotel,
      cotizadorHotels: state => state.app.cotizadorHotels,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorHotelsResultPaginate: state => state.app.cotizadorHotelsResultPaginate,
      paginationResult: state => state.app.paginationResult,
      itemsPerPage: state => state.app.itemsPerPage,
      showMapHotels: state => state.app.showMapHotels,
      cotizadorHotelsResultFilters: state => state.app.cotizadorHotelsResultFilters,
      pagination: state => state.app.pagination,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
      configCotizadorHotel: state => state.app.configCotizadorHotel,
      maxAgeCotizadorHotel: state => state.app.maxAgeCotizadorHotel,
    }),
    computedFromDateFormatted() {
      return this.cotizadorHotels.dateIn ? this.$moment(this.cotizadorHotels.dateIn).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.cotizadorHotels.dateOut ? this.$moment(this.cotizadorHotels.dateOut).format('D-MMM-YY') : ''
    },
    daysSearch() {
      let days = 1
      if (this.cotizadorHotels.dateIn && this.cotizadorHotels.dateOut) {
        days = this.$moment(this.cotizadorHotels.dateOut).diff(this.$moment(this.cotizadorHotels.dateIn), 'days')
      }

      return days
    },
    cantViajeros() {
      let cant = 0
      this.cotizadorHotels.ocupation.forEach(element => {
        cant += element.adults + element.childs
      })

      return cant
    },
  },
  watch: {
    searchDestinos(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestinos(val.toLowerCase())
      } else {
        this.noResultDestinos = false
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
    searchTipoDestino(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTipoDestino(val.toLowerCase())
    },
  },

  mounted() {
    // this.scroll()
    this.getConfigSearch()
  },
  created() {
    this.profile()
    this.fetchDataFromEndpoints()

    if (localStorage.getItem('clear-cotization') !== null) {
      this.clearForm()
      localStorage.removeItem('clear-cotization')
    }
  },
  methods: {
    ...mapMutations([
      'setCotizadorHotelsResult',
      'setCotizadorHotelsResultOrigin',
      'setCotizadorHotelsResultPaginate',
      'setSearchingHotel',
      'setShowRoomHotelCotizador',
      'updatePaginationResult',
      'updateItemsPerPageResultSearch',
      'addCotizadorHotelsResultPaginate',
      'emptyCotizadorHotels',
      'updateShowMap',
      'updateAllHotels',
      'updateIsLoadingSearch',
      'resetPagination',
      'setPagination',
      'updatePagination',
      'setIsLoadingPagination',
    ]),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
    },
    getConfigSearch() {
      if (this.configCotizadorHotel.days_calendar_in) {
        this.minDateOrigin = this.$moment(new Date())
          .add(this.configCotizadorHotel.days_calendar_in, 'days')
          .toDate()

        if (localStorage.getItem('dateIn') !== null) {
          this.minDate = this.$moment(localStorage.getItem('dateIn'))
            .toDate()
        } else {
          this.minDate = this.$moment(new Date())
            .add(this.configCotizadorHotel.days_calendar_in, 'days')
            .toDate()
        }

        this.cotizadorHotels.dateIn = this.minDate.toISOString().substr(0, 10)

        if (localStorage.getItem('dateOut') !== null) {
          this.cotizadorHotels.dateOut = this.$moment(localStorage.getItem('dateOut'))
            .format('YYYY-MM-DD')
        } else {
          this.cotizadorHotels.dateOut = this.$moment(this.cotizadorHotels.dateIn)
            .add('days', 1)
            .format('YYYY-MM-DD')
        }
      }

      /* this.axios
        .get('config-search/hotels', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.configSearch = res.data.data.data.parameters
          if (this.configSearch.days_calendar_in) {
            this.minDateOrigin = this.$moment(new Date())
              .add(this.configSearch.days_calendar_in, 'days')
              .toDate()

            if (localStorage.getItem('dateIn') !== null) {
              this.minDate = this.$moment(localStorage.getItem('dateIn'))
                .toDate()
            } else {
              this.minDate = this.$moment(new Date())
                .add(this.configSearch.days_calendar_in, 'days')
                .toDate()
            }

            this.cotizadorHotels.dateIn = this.minDate.toISOString().substr(0, 10)

            if (localStorage.getItem('dateOut') !== null) {
              this.cotizadorHotels.dateOut = this.$moment(localStorage.getItem('dateOut'))
                .format('YYYY-MM-DD')
            } else {
              this.cotizadorHotels.dateOut = this.$moment(this.cotizadorHotels.dateIn)
                .add('days', 1)
                .format('YYYY-MM-DD')
            }
          }
        }) */
    },
    getItemText(item) {
      return `${item.name} ${item.slug}`
    },
    scrollInto(elementId) {
      const section = document.querySelector(`#${elementId}`)
      section.scrollIntoView({
        behavior: 'smooth',
      })
    },
    filterDestinos(v) {
      this.itemsDestinos = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        // DESTINOS
        // const url = `${this.api_v1_1}destinos-hotels-filter-contrate`
        const url = 'destinos-hotels-filter-contrate'
        this.axios
          .post(url, { search: v }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              this.itemsDestinos.push(element)

              // eslint-disable-next-line no-nested-ternary
              /* this.itemsDestinos.push({
                id: element.id,
                name: element.destino,
                icon: 'mdi-map-marker',
                type: 'destino',
                tag: element.tag,
              }) */
            })
          })

        // HOTELES
        // const urlH = `${this.api_v1_1}hotels-filter-contrate`
        /* const urlH = 'hotels-filter-contrate'
        this.axios
          .post(urlH, { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestinos.push({
                id: element.id,
                name: element.name,
                icon: 'mdi-bed-outline',
                type: 'hotel',
                tag: 'hotel',
              })
            })
          }) */

        setTimeout(() => {
          if (this.itemsDestinos.length === 0) {
            this.noResultDestinos = true
          }
        }, 500)
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    filterTipoDestino() {
      this.itemsTipoDestino = this.allTipoDestino.filter(e => e.name.toLowerCase())
    },
    clearForm() {
      this.emptyCotizadorHotels()
      this.oneSearch = false

      /* this.cotizadorHotels = {
        destino: null,
        dateIn: null,
        dateOut: null,
        proveedor_id: null,
        categoria_id: [],
        tipo_destino_id: null,
        ocupation: [
          {
            adults: 2,
            childs: 0,
            edades: [],
            room_id: null,
            paxs: [],
            showAllPaxs: false,
            priceTotal: null,
            priceWithMarkups: null,
            priceWithDescuento: null,
          },
        ],
      } */

      this.resetPagination()
      this.setCotizadorHotelsResult([])
      this.setCotizadorHotelsResultOrigin([])
      this.setCotizadorHotelsResultPaginate([])
      this.setSearchingHotel(false)
      this.clearDestinos()

      localStorage.removeItem('dateIn')
      localStorage.getItem('dateIn')

      this.minDate = this.minDateOrigin
    },

    async fetchDataFromEndpoints() {
      try {
        const [
          resProveedores,
          resCategories,
          resTipoDestino,
        ] = await Promise.all([
          this.axios
            .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        this.proveedores = resProveedores.data.data
        this.categories = resCategories.data.data
        this.allTipoDestino = resTipoDestino.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },

    /*
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoDestino = res.data.data

          // this.itemsTipoDestino = res.data.data
        })
    },
    getMaxAgeJoven() {
      this.axios
        .get('config-search-max-age-hotels', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.max_age = res.data.data.age
          }
        })
    },
    */

    /* scroll() {
      window.onscroll = () => {
        const bottomOfWindow = Math.ceil((document.documentElement.scrollTop + window.innerHeight)) === Math.ceil(document.documentElement.offsetHeight)
        if (bottomOfWindow) {
          // PAGINAR SI LOS FILTROS ESTAN LIMPIO
          const filters1 = JSON.stringify({
            name: null,
            categories: [],
            price: [0, 100000],
            tipoDestinos: [],
            cadenaHotelera: [],
            tipoReserva: [],
            contratos: [],
            services: [],
            intereses: [],
            orderByRecomendado: null,
            orderByCategory: null,
            orderByPrice: 'asc',
          })
          const filters2 = JSON.stringify(this.cotizadorHotelsResultFilters)

          if (filters1 === filters2) {
            let cont = 0

            // eslint-disable-next-line no-plusplus
            for (let index = parseInt(this.cotizadorHotelsResultPaginate.length, 10); index < this.cotizadorHotelsResult.length; index++) {
              cont += 1
              if (cont === this.itemsPerPageResultSearch) {
                break
              }
              this.addCotizadorHotelsResultPaginate(this.cotizadorHotelsResult[index])
            }
          }
        }
      }
    }, */

    /* clearForm() {
      this.cotizadorHotels.destino = null
      this.cotizadorHotels.dateIn = null
      this.cotizadorHotels.dateOut = null
      this.cotizadorHotels.proveedor_id = null
      this.cotizadorHotels.categoria_id = null
      this.cotizadorHotels.tipo_destino_id = null

      // this.cotizadorHotels.ocupation.length = 0

      this.setCotizadorHotelsResult([])
      this.setSearchingHotel(false)
    }, */

    onPageChange() {
      this.paginando = true
      this.search()
    },

    search() {
      this.oneSearch = false
      if (
        this.cotizadorHotels.destino
        && this.cotizadorHotels.dateIn
        && this.cotizadorHotels.dateOut
        && this.cotizadorHotels.ocupation.length > 0
      ) {
        this.loadingSearch = true
        let proceder = true

        this.cotizadorHotels.ocupation.forEach(room => {
          if (room.childs > 0) {
            room.edades.forEach(edad => {
              // eslint-disable-next-line no-empty
              if (edad.edad === 0) {} else if (!edad.edad) {
                proceder = false
              }
            })
          }
        })

        if (proceder) {
          // GUARDAR LAS FECHAS
          localStorage.setItem('dateIn', this.cotizadorHotels.dateIn)
          localStorage.setItem('dateOut', this.cotizadorHotels.dateOut)

          this.setSearchingHotel(true)

          this.setCotizadorHotelsResult([])
          this.setCotizadorHotelsResultOrigin([])
          this.setCotizadorHotelsResultPaginate([])
          // eslint-disable-next-line no-plusplus
          /* for (let index = 0; index < this.cotizadorHotels.ocupation.length; index++) {
            this.cotizadorHotels.ocupation[index].cant_adul = this.cotizadorHotels.ocupation[index].adults
            this.cotizadorHotels.ocupation[index].cant_teen = 0
            this.cotizadorHotels.ocupation[index].cant_men = 0
            this.cotizadorHotels.ocupation[index].cant_inf = 0
          } */
          const json = this.cotizadorHotels

          // eslint-disable-next-line radix
          json.page = this.pagination.current
          json.per_page = this.per_page
          json.currency_code = this.currencyCodeFacturar

          // json.per_page = parseInt(this.page) * parseInt(this.per_page)

          // const url = `${this.api_v1_1}cotizador-search-hotel`
          const url = 'cotizador-search-hotel'

          if (json.destino.type === 'destino') {
            this.isLoadingSkeleton = true
            this.hotelsSkeleton = []
            const jsonDest = {
              destino: json.destino.name,
            }
            this.axios.post('cotizador-search-hotel-by-destino', jsonDest, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
              .then(res => {
                if (res.data.success) {
                  this.hotelsSkeleton = res.data.data.data
                }
              })
              .finally(() => {
                this.isLoadingSkeleton = false
              })
          }

          this.axios.post(url, json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
            .then(res => {
              this.oneSearch = true

              // this.setSearchingHotel(true)

              if (res.data.success) {
                this.setCotizadorHotelsResult(res.data.data.items)
                this.setCotizadorHotelsResultOrigin(res.data.data.items)
                this.updateAllHotels(res.data.data.allHotel)

                const arr = []
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < this.itemsPerPage; index++) {
                  if (res.data.data.items[index]) {
                    arr.push(res.data.data.items[index])
                  }
                }
                this.setIsLoadingPagination(false)
                this.setCotizadorHotelsResultPaginate(arr)
                this.updateIsLoadingSearch(false)

                this.updatePagination({
                  current: 1,
                  total: Math.ceil(parseInt(res.data.data.total_items, parseInt(this.itemsPerPage, 10)) / 10),
                })

                // this.pagination.current = res.data.data.page
                // this.pagination.total = Math.ceil(parseInt(res.data.data.total_items, 10) / parseInt(this.itemsPerPageResultSearch, 10))
                // this.updatePaginationResult(this.pagination)
              }
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.loadingSearch = false
              this.paginando = false

              this.setSearchingHotel(false)
            })
        } else {
          this.loadingSearch = false
          this.paginando = false
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.loadingSearch = false
        this.paginando = false
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearDestinos() {
      this.searchDestinos = null
      this.cotizadorHotels.destino = null
      this.itemsDestinos = []
    },
    inputDestinos() {
      if (this.cotizadorHotels.destino.name === null || this.cotizadorHotels.destino.name === '') {
        this.clearDestinos()
      }
    },
    changeDestinos() {
    },

    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
        this.cotizadorHotels.dateOut = this.$moment(this.cotizadorHotels.dateIn)
          .add('days', 1)
          .format('YYYY-MM-DD')
      }, 100)
    },

    changeOcupation() {
      this.setShowRoomHotelCotizador(!this.showOcupationRoomHotel)
    },
  },
}
</script>

<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}

.ps {
  height: 100%;
}
.text-message {
  font-size: 12px;
}
.title {
  font-size: 15px !important;
}
.container-chat {
  position: fixed;
  z-index: 1999;
  top: 6rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

/* PARA LA FECHA DE VCALENDAR*/
.vc-popover-content-wrapper {
  margin: 0px;
  position: fixed !important;
  inset: 0px auto auto 0px;
  transform: translate(150px, 80px);
}
</style>
